// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <svg
    id="svg153999"
    style={{height:"50px",minWidth:"100px"}}
    // height="180px"
    // width="750px"
    viewBox="0.0 0.0 750.0 200.0"
  >
    {/* <svg version="1.1" x="0.0" y="0.0"> */}
    <defs id="defs154003" />
    <linearGradient
      spreadMethod="pad"
      y2="30%"
      x2="-10%"
      y1="120%"
      x1="30%"
      id="3d_gradient2-logo-a1cbd38b-528d-4791-b20d-47ec6cb5ac50"
    >
      <stop id="stop153980" stopOpacity={1} stopColor="#ffffff" offset="0%" />
      <stop id="stop153982" stopOpacity={1} stopColor="#000000" offset="100%" />
    </linearGradient>
    <linearGradient
      gradientTransform="rotate(-30)"
      spreadMethod="pad"
      y2="30%"
      x2="-10%"
      y1="120%"
      x1="30%"
      id="3d_gradient3-logo-a1cbd38b-528d-4791-b20d-47ec6cb5ac50"
    >
      <stop id="stop153985" stopOpacity={1} stopColor="#ffffff" offset="0%" />
      <stop id="stop153987" stopOpacity={1} stopColor="#cccccc" offset="50%" />
      <stop id="stop153989" stopOpacity={1} stopColor="#000000" offset="100%" />
    </linearGradient>
    <g id="logo-group">
      <image
        xlinkHref=""
        id="container"
        x={272}
        y={144}
        width={480}
        height={480}
        style={{
          display: "none",
        }}
        transform="translate(0 0)"
      />
      <g id="logo-center" transform="translate(0 -285)">
        <image
          xlinkHref=""
          id="icon_container"
          x={0}
          y={0}
          style={{
            display: "none",
          }}
        />
        <g
          id="slogan"
          style={{
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 32,
            lineHeight: 1,
            fontFamily: "'Montserrat Light Alt1'",
            fontVariantLigatures: "none",
            textAlign: "center",
            textAnchor: "middle",
          }}
          transform="translate(0 0)"
        />
        <g
          id="title"
          style={{
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 72,
            lineHeight: 1,
            fontFamily: "'Montserrat Light Alt1'",
            fontVariantLigatures: "none",
            textAlign: "center",
            textAnchor: "middle",
          }}
          transform="translate(0 0)"
        >
          <path
            id="path154008"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="M 136.31525,-50.4 V 0 h 3.672 v -50.4 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#2d244c"
            stroke="#2d244c"
            transform="translate(0 273.78999999999996) translate(50 30.20000000000001) scale(1.4) translate(-136.31525 50.4)"
          />
          <path
            id="path154010"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 198.58962,-50.4 h -3.672 v 43.776 l -34.272,-43.776 h -3.096 V 0 h 3.672 V -43.776 L 195.56562,0 h 3.024 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#2d244c"
            stroke="#2d244c"
            transform="translate(0 273.78999999999996) translate(79.72811800000002 30.20000000000001) scale(1.4) translate(-157.54962 50.4)"
          />
          <path
            id="path154012"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 224.11025,-2.952 c 3.96,2.232 8.424,3.312 13.392,3.312 3.6,0 6.912,-0.576 10.008,-1.8 3.096,-1.152 5.76,-2.952 7.92,-5.256 l -2.304,-2.376 c -4.104,4.032 -9.288,6.048 -15.48,6.048 -4.248,0 -8.064,-0.936 -11.52,-2.88 -3.456,-1.872 -6.192,-4.536 -8.136,-7.92 -2.016,-3.384 -2.952,-7.2 -2.952,-11.376 0,-4.176 0.936,-7.92 2.952,-11.304 1.944,-3.384 4.68,-6.048 8.136,-7.992 3.456,-1.872 7.272,-2.88 11.52,-2.88 6.264,0 11.448,2.016 15.48,5.976 l 2.304,-2.376 c -2.16,-2.304 -4.824,-4.032 -7.848,-5.184 -3.096,-1.152 -6.48,-1.8 -10.08,-1.8 -4.968,0 -9.432,1.152 -13.392,3.312 -3.96,2.232 -7.056,5.256 -9.36,9.144 -2.304,3.888 -3.384,8.28 -3.384,13.104 0,4.896 1.08,9.216 3.384,13.104 2.304,3.888 5.4,6.984 9.36,9.144 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#2d244c"
            stroke="#2d244c"
            transform="translate(0 273.78999999999996) translate(155.07140000000004 29.696000000000005) scale(1.4) translate(-211.36625 50.76)"
          />
          <path
            id="path154014"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 284.82537,-50.4 h -3.672 L 257.96938,0 h 3.95999 l 21.024,-46.368 21.096,46.368 h 3.96 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#2d244c"
            stroke="#2d244c"
            transform="translate(0 273.78999999999996) translate(220.315782 30.20000000000001) scale(1.4) translate(-257.96938 50.4)"
          />
          <path
            id="path154016"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 349.775,-27.216 h -32.616 v 3.24 h 32.616 z m 0,-23.184 h -32.616 v 3.312 h 32.616 z M 317.159,-3.312 V 0 h 32.616 v -3.312 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#2d244c"
            stroke="#2d244c"
            transform="translate(0 273.78999999999996) translate(303.18125000000003 30.20000000000001) scale(1.4) translate(-317.159 50.4)"
          />
          <path
            id="path154018"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="M 365.39337,-50.4 V 0 h 32.688 V -3.312 H 369.06538 V -50.4 Z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#2d244c"
            stroke="#2d244c"
            transform="translate(0 273.78999999999996) translate(370.70936800000004 30.20000000000001) scale(1.4) translate(-365.39337 50.4)"
          />
          <path
            id="path154020"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="M 426.14337,-50.4 V 0 h 3.67201 v -50.4 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(50 119.66) scale(1.4) translate(-426.14337 50.4)"
          />
          <path
            id="path154022"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 488.41775,-50.4 h -3.672 v 43.776 l -34.272,-43.776 h -3.096 V 0 h 3.672 V -43.776 L 485.39375,0 h 3.024 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(79.72813199999999 119.66) scale(1.4) translate(-447.37775 50.4)"
          />
          <path
            id="path154024"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="M 515.66638,-47.088 V 0 h 3.672 v -47.088 h 18.144 V -50.4 h -39.96001 v 3.312 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(149.9306 119.66) scale(1.4) translate(-497.52237 50.4)"
          />
          <path
            id="path154026"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 579.13437,-27.216 h -32.616 v 3.24 h 32.616 z m 0,-23.184 h -32.616 v 3.312 h 32.616 z m -32.616,47.088 V 0 h 32.616 v -3.312 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(218.52499999999998 119.66) scale(1.4) translate(-546.51837 50.4)"
          />
          <path
            id="path154028"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 621.32075,-17.64 c 3.744,-1.08 6.696,-2.952 8.712,-5.688 2.016,-2.736 3.096,-6.048 3.096,-10.08 0,-5.256 -1.8,-9.432 -5.4,-12.456 -3.6,-3.024 -8.568,-4.536 -14.904,-4.536 h -18.072 v 3.312 h 18.072 c 5.328,0 9.432,1.224 12.312,3.6 2.808,2.376 4.248,5.76 4.248,10.08 0,4.392 -1.44,7.776 -4.248,10.152 -2.88,2.376 -6.984,3.528 -12.312,3.528 h -18.072 V 0 h 3.672 v -16.488 h 14.4 c 1.44,0 3.096,-0.072 4.896,-0.36 L 629.74475,0 h 4.176 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(286.05313199999995 119.66) scale(1.4) translate(-594.75275 50.4)"
          />
          <path
            id="path154030"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 665.07537,-50.4 h -3.672 L 638.21938,0 h 3.96 l 21.024,-46.368 21.096,46.368 h 3.95999 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(346.9064139999999 119.66) scale(1.4) translate(-638.21938 50.4)"
          />
          <path
            id="path154032"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 704.62587,-2.952 c 3.96,2.232 8.42401,3.312 13.392,3.312 3.60001,0 6.91201,-0.576 10.00801,-1.8 3.096,-1.152 5.76,-2.952 7.91999,-5.256 l -2.30399,-2.376 c -4.10401,4.032 -9.288,6.048 -15.48001,6.048 -4.248,0 -8.06399,-0.936 -11.51999,-2.88 -3.45601,-1.872 -6.192,-4.536 -8.13601,-7.92 -2.016,-3.384 -2.952,-7.2 -2.952,-11.376 0,-4.176 0.936,-7.92 2.952,-11.304 1.94401,-3.384 4.68,-6.048 8.13601,-7.992 3.456,-1.872 7.27199,-2.88 11.51999,-2.88 6.26401,0 11.448,2.016 15.48001,5.976 l 2.30399,-2.376 c -2.15999,-2.304 -4.82399,-4.032 -7.84799,-5.184 -3.096,-1.152 -6.48,-1.8 -10.08001,-1.8 -4.96799,0 -9.432,1.152 -13.392,3.312 -3.95999,2.232 -7.05599,5.256 -9.35999,9.144 -2.30401,3.888 -3.384,8.28 -3.384,13.104 0,4.896 1.07999,9.216 3.384,13.104 2.304,3.888 5.4,6.984 9.35999,9.144 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(422.033914 119.15599999999998) scale(1.4) translate(-691.88188 50.76)"
          />
          <path
            id="path154034"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="M 757.26013,-47.088 V 0 h 3.672 v -47.088 h 18.144 V -50.4 h -39.96001 v 3.312 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(488.16184999999996 119.66) scale(1.4) translate(-739.11612 50.4)"
          />
          <path
            id="path154036"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="M 788.11212,-50.4 V 0 h 3.67201 v -50.4 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(556.7562499999999 119.66) scale(1.4) translate(-788.11212 50.4)"
          />
          <path
            id="path154038"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 848.89138,-50.4 h -3.81601 l -20.37599,45.648 -20.376,-45.648 h -4.032 L 822.75537,0 h 3.672 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(573.8072139999999 119.66) scale(1.4) translate(-800.29138 50.4)"
          />
          <path
            id="path154040"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: 72,
              lineHeight: 1,
              fontFamily: "'Montserrat Light Alt1'",
              fontVariantLigatures: "none",
              textAlign: "center",
              textAnchor: "middle",
            }}
            d="m 889.63437,-27.216 h -32.616 v 3.24 h 32.616 z m 0,-23.184 h -32.616 v 3.312 h 32.616 z m -32.616,47.088 V 0 h 32.616 v -3.312 z"
            strokeWidth={0}
            strokeLinejoin="miter"
            strokeMiterlimit={2}
            fill="#6e4ccf"
            stroke="#6e4ccf"
            transform="translate(0 273.78999999999996) translate(653.2249999999999 119.66) scale(1.4) translate(-857.01837 50.4)"
          />
        </g>
        <image
          xlinkHref=""
          id="icon"
          x={0}
          y={0}
          style={{
            display: "none",
          }}
        />
      </g>
    </g>
    {/* </svg> */}
  </svg>
  );
};

export default Logo;
