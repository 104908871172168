// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    // {
    //   id: 'default',
    //   title: 'Dashboard',
    //   type: 'item',
    //   url: '/dashboard/default',
    //   icon: icons.IconDashboard,
    //   breadcrumbs: false
    // },
    {
      id: 'insert-data',
      title: 'Insert Data',
      type: 'item',
      url: '/insert-data',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
